// Import necessary dependencies
// import React from 'react';
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';

let sender_id = "";
// Define a component that will display query parameters
const QueryParamComponent = () => {
  // Use React Router's useParams hook to access query parameters
  let { param1 } = useParams();
  sender_id = param1
  console.log(sender_id)
  useEffect(() => {
    // Store value in localStorage
    sessionStorage.setItem('myValue', sender_id);
  }, []);
  return (
    <div>
    </div>
  );
};

// Set up routing in your main component
const App = () => {
  return (
    <Router>
      <Routes>
        {/* Route to handle URL with query parameters */}
        <Route path="/:param1" element={<QueryParamComponent />} />
      </Routes>
    </Router>
  );
};

export default App;
